<template>
  <v-card class="rounded-lg shadow-regular">
    <v-card-text class="py-5 px-7">
      <p class="card-title mb-0 black--text">Input Rules</p>
      <v-container>
        <v-row justify="space-between" class="my-2">
          <!-- Common Rules Section -->
          <v-col cols="6" class="mr-3 container-style">
            <p class="section-title mb-0 text-center">Common Rules</p>
            <v-container>
              <!-- Field Required -->
              <v-row
                class="d-flex flex-column container-style py-2 my-3 mx-1 px-1"
                align="center"
              >
                <div class="d-flex justify-center">
                  <p class="mt-2 mr-2 style-label">Field Required</p>
                  <v-btn
                    icon
                    color="caribbeanDark"
                    @click="copyToClipboard('Field Required')"
                  >
                    <v-icon>mdi-code-tags</v-icon>
                  </v-btn>
                </div>
                <v-col>
                  <!-- Text Input -->
                  <RegularTextInput
                    label="Label*"
                    placeholder="Placeholder"
                    v-model="fieldRequired"
                    :rules="[$rules.fieldRequired('This field is required')]"
                  />
                  <!-- Text Input -->
                </v-col>
              </v-row>

              <!-- Numbers and Letters -->
              <v-row
                class="d-flex flex-column container-style py-2 my-3 mx-1 px-1"
                align="center"
              >
                <div class="d-flex justify-center">
                  <p class="mt-2 mr-2 style-label">Numbers and Letters</p>
                  <v-btn
                    icon
                    color="caribbeanDark"
                    @click="copyToClipboard('Numbers and Letters')"
                  >
                    <v-icon>mdi-code-tags</v-icon>
                  </v-btn>
                </div>
                <v-col>
                  <!-- Text Input -->
                  <RegularTextInput
                    label="Label"
                    placeholder="Placeholder"
                    v-model="numbersAndLetters"
                    :rules="[
                      $rules.numbersAndLetters('Only numbers and letters'),
                    ]"
                  />
                  <!-- Text Input -->
                </v-col>
              </v-row>

              <!-- Only Numbers -->
              <v-row
                class="d-flex flex-column container-style py-2 my-3 mx-1 px-1"
                align="center"
              >
                <div class="d-flex justify-center">
                  <p class="mt-2 mr-2 style-label">Only Numbers</p>
                  <v-btn
                    icon
                    color="caribbeanDark"
                    @click="copyToClipboard('Only Numbers')"
                  >
                    <v-icon>mdi-code-tags</v-icon>
                  </v-btn>
                </div>
                <v-col>
                  <!-- Text Input -->
                  <RegularTextInput
                    label="Label"
                    placeholder="Placeholder"
                    v-model="onlyNumbers"
                    :rules="[$rules.onlyNumbers('Only numbers')]"
                  />
                  <!-- Text Input -->
                </v-col>
              </v-row>

              <!-- Number with decimals -->
              <v-row
                class="d-flex flex-column container-style py-2 my-3 mx-1 px-1"
                align="center"
              >
                <div class="d-flex justify-center">
                  <p class="mt-2 mr-2 style-label">Number with Decimals</p>
                  <v-btn
                    icon
                    color="caribbeanDark"
                    @click="copyToClipboard('Number with Decimals')"
                  >
                    <v-icon>mdi-code-tags</v-icon>
                  </v-btn>
                </div>
                <v-col>
                  <!-- Text Input -->
                  <RegularTextInput
                    label="Label"
                    placeholder="Placeholder"
                    v-model="numberWithDecimals"
                    :rules="[
                      $rules.numberWithDecimals('Only numbers and decimals'),
                    ]"
                  />
                  <!-- Text Input -->
                </v-col>
              </v-row>

              <!-- Currency -->
              <v-row
                class="d-flex flex-column container-style py-2 my-3 mx-1 px-1"
                align="center"
              >
                <div class="d-flex justify-center">
                  <p class="mt-2 mr-2 style-label">Currency</p>
                  <v-btn
                    icon
                    color="caribbeanDark"
                    @click="copyToClipboard('Currency')"
                  >
                    <v-icon>mdi-code-tags</v-icon>
                  </v-btn>
                </div>
                <v-col>
                  <!-- Text Input -->
                  <RegularTextInput
                    label="Label"
                    placeholder="Placeholder"
                    v-model="currency"
                    :rules="[$rules.currency('Only currencies are allowed')]"
                  />
                  <!-- Text Input -->
                </v-col>
              </v-row>

              <!-- Phone Number -->
              <v-row
                class="d-flex flex-column container-style py-2 my-3 mx-1 px-1"
                align="center"
              >
                <div class="d-flex justify-center">
                  <p class="mt-2 mr-2 style-label">Phone Number</p>
                  <v-btn
                    icon
                    color="caribbeanDark"
                    @click="copyToClipboard('Phone Number')"
                  >
                    <v-icon>mdi-code-tags</v-icon>
                  </v-btn>
                </div>
                <v-col>
                  <!-- Text Input -->
                  <RegularTextInput
                    label="Label"
                    placeholder="Placeholder"
                    v-model="phoneNumber"
                    :rules="[$rules.phoneNumber('Not a valid phone number')]"
                  />
                  <!-- Text Input -->
                </v-col>
              </v-row>
            </v-container>
          </v-col>

          <!-- Special Rules Section -->
          <v-col cols="5" class="mr-3 container-style">
            <p class="section-title mb-0 text-center">Special rules</p>
            <v-container>
              <!-- Vehicle Plate -->
              <v-row
                class="d-flex flex-column container-style py-2 my-3 mx-1 px-1"
                align="center"
              >
                <div class="d-flex justify-center">
                  <p class="mt-2 mr-2 style-label">Vehicle Plate</p>
                  <v-btn
                    icon
                    color="caribbeanDark"
                    @click="copyToClipboard('Vehicle Plate')"
                  >
                    <v-icon>mdi-code-tags</v-icon>
                  </v-btn>
                </div>
                <v-col>
                  <!-- Text Input -->
                  <RegularTextInput
                    label="Label"
                    placeholder="Placeholder"
                    v-model="vehiclePlate"
                    :rules="[$rules.vehiclePlate('Not a valid vehicle plate')]"
                  />
                  <!-- Text Input -->
                </v-col>
              </v-row>

              <!-- Exact Length -->
              <v-row
                class="d-flex flex-column container-style py-2 my-3 mx-1 px-1"
                align="center"
              >
                <div class="d-flex justify-center">
                  <p class="mt-2 mr-2 style-label">Exact Length</p>
                  <v-btn
                    icon
                    color="caribbeanDark"
                    @click="copyToClipboard('Exact Length')"
                  >
                    <v-icon>mdi-code-tags</v-icon>
                  </v-btn>
                </div>
                <v-col>
                  <!-- Text Input -->
                  <RegularTextInput
                    label="Label"
                    placeholder="Placeholder"
                    v-model="exactLength"
                    :rules="[$rules.totalSize(7, 'Invalid length')]"
                  />
                  <!-- Text Input -->
                </v-col>
              </v-row>

              <!-- Greater than 0 -->
              <v-row
                class="d-flex flex-column container-style py-2 my-3 mx-1 px-1"
                align="center"
              >
                <div class="d-flex justify-center">
                  <p class="mt-2 mr-2 style-label">Greater than 0</p>
                  <v-btn
                    icon
                    color="caribbeanDark"
                    @click="copyToClipboard('Greater than 0')"
                  >
                    <v-icon>mdi-code-tags</v-icon>
                  </v-btn>
                </div>
                <v-col>
                  <!-- Text Input -->
                  <RegularTextInput
                    label="Label"
                    placeholder="Placeholder"
                    v-model="greaterThanZero"
                    :rules="[
                      $rules.greaterThanZero('Value must be greater than 0'),
                    ]"
                  />
                  <!-- Text Input -->
                </v-col>
              </v-row>

              <!-- Smaller or Equal to -->
              <v-row
                class="d-flex flex-column container-style py-2 my-3 mx-1 px-1"
                align="center"
              >
                <div class="d-flex justify-center">
                  <p class="mt-2 mr-2 style-label">Smaller or Equal to</p>
                  <v-btn
                    icon
                    color="caribbeanDark"
                    @click="copyToClipboard('Smaller or Equal to')"
                  >
                    <v-icon>mdi-code-tags</v-icon>
                  </v-btn>
                </div>
                <v-col>
                  <!-- Text Input -->
                  <RegularTextInput
                    label="Label"
                    placeholder="Placeholder"
                    v-model="smallerEqualTo"
                    :rules="[
                      $rules.smallerOrEqualThan(
                        5,
                        'Value must be Smaller/Equal to 5'
                      ),
                    ]"
                  />
                  <!-- Text Input -->
                </v-col>
              </v-row>

              <!-- Greater or Equal to -->
              <v-row
                class="d-flex flex-column container-style py-2 my-3 mx-1 px-1"
                align="center"
              >
                <div class="d-flex justify-center">
                  <p class="mt-2 mr-2 style-label">Greater or Equal to</p>
                  <v-btn
                    icon
                    color="caribbeanDark"
                    @click="copyToClipboard('Greater or Equal to')"
                  >
                    <v-icon>mdi-code-tags</v-icon>
                  </v-btn>
                </div>
                <v-col>
                  <!-- Text Input -->
                  <RegularTextInput
                    label="Label"
                    placeholder="Placeholder"
                    v-model="greaterEqualTo"
                    :rules="[
                      $rules.biggerOrEqualThan(
                        5,
                        'Value must be greater/equal to 5'
                      ),
                    ]"
                  />
                  <!-- Text Input -->
                </v-col>
              </v-row>

              <!-- Required by Parameter -->
              <v-row
                class="d-flex flex-column container-style py-2 my-3 mx-1 px-1"
                align="center"
              >
                <div class="d-flex justify-center">
                  <p class="mt-2 mr-2 style-label">Required by Parameter</p>
                  <v-btn
                    icon
                    color="caribbeanDark"
                    @click="copyToClipboard('Required by Parameter')"
                  >
                    <v-icon>mdi-code-tags</v-icon>
                  </v-btn>
                </div>
                <v-col>
                  <!-- Text Input -->
                  <RegularTextInput
                    label="Label"
                    placeholder="Placeholder"
                    v-model="requiredByParam"
                    :rules="[
                      $rules.fieldRequiredByParameter(
                        true,
                        'This field is required'
                      ),
                    ]"
                  />
                  <!-- Text Input -->
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import RegularTextInput from '@/components/shared/customComponents/inputs/regular-text-input.vue';

export default {
  name: 'InputRulesWiki',
  components: {
    RegularTextInput,
  },

  data: () => ({
    fieldRequired: '',
    numbersAndLetters: '',
    onlyNumbers: '',
    numberWithDecimals: '',
    currency: '',
    phoneNumber: '',
    vehiclePlate: '',
    exactLength: '',
    greaterThanZero: '',
    smallerEqualTo: '',
    greaterEqualTo: '',
    requiredByParam: '',
  }),

  methods: {
    selectedComponentHandler(name) {
      let selectedComponent = null;

      switch (name) {
        case 'Field Required':
          selectedComponent = `:rules="[$rules.fieldRequired('This field is required')]"`;
          break;
        case 'Numbers and Letters':
          selectedComponent = `:rules="[$rules.numbersAndLetters('Only numbers and letters'),]"`;
          break;
        case 'Only Numbers':
          selectedComponent = `:rules="[$rules.onlyNumbers('Only numbers')]"`;
          break;
        case 'Number with Decimals':
          selectedComponent = `:rules="[$rules.numberWithDecimals('Only numbers and decimals')]"`;
          break;
        case 'Currency':
          selectedComponent = `:rules="[$rules.currency('Only currencies are allowed')]"`;
          break;
        case 'Phone Number':
          selectedComponent = `:rules="[$rules.phoneNumber('Not a valid phone number')]"`;
          break;
        case 'Vehicle Plate':
          selectedComponent = `:rules="[$rules.vehiclePlate('Not a valid vehicle plate')]"`;
          break;
        case 'Exact Length':
          selectedComponent = `:rules="[$rules.totalSize(7, 'Invalid length')]"`;
          break;
        case 'Greater than 0':
          selectedComponent = `:rules="[$rules.greaterThanZero('Value must be greater than 0')]"`;
          break;
        case 'Smaller or Equal to':
          selectedComponent = `:rules="[$rules.smallerOrEqualThan(5,'Value must be Smaller/Equal to 5')]"`;
          break;
        case 'Greater or Equal to':
          selectedComponent = `:rules="[$rules.biggerOrEqualThan(5,'Value must be greater/equal to 5')]"`;
          break;
        case 'Required by Parameter':
          selectedComponent = `:rules="[$rules.biggerOrEqualThan(true,'This field is required')]"`;
          break;
      }
      return selectedComponent;
    },

    triggerToast(name) {
      const toastParams = {
        position: { top: true, right: true },
        timeout: 3000,
        messages: { success: `${name} copied to clipboard` },
        type: 'success',
      };

      this.$store.dispatch(
        'toastNotification/setupToastNotification',
        toastParams
      );
      this.$store.dispatch('toastNotification/showToastNotification', true);
    },

    copyToClipboard(name) {
      let component = this.selectedComponentHandler(name);
      navigator.clipboard.writeText(component);
      this.triggerToast(name);
    },
  },
};
</script>

<style scoped lang="scss">
.container-style {
  border: 1px dashed $grey-regular !important;
  border-radius: 5px;
}

.style-label {
  font-size: 13px !important;
}
</style>
